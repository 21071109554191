* {
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}

.body__light__theme {
    background-color: var(--light-mode-bg);
}

.body__dark__theme {
    background-color: var(--dark-mode-bg);
}