.about__section {
    padding: 1.5em;
    border-radius: 0.5em;
    letter-spacing: 0.05em;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
    max-width: calc(1024px - 2em);
    margin: 0 auto;
}

.about__section__light {
    background-color: var(--light-mode-EL);
}

.about__section__dark {
    background-color: var(--dark-mode-EL);
}

.about__header {
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.about__text {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.75em;
}

.about__wave {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: 5;
    transform-origin: 70% 70%;
    display: inline-block;
}
  
@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }
    100% { transform: rotate( 0.0deg) }
}