:root {
    --dark-mode-EL: hsl(209, 23%, 22%);
    --dark-mode-bg: hsl(207, 26%, 17%);
    --dark-mode-text: hsl(0, 0%, 100%);
    --dark-mode-input: hsl(0, 0%, 65%);
    --light-mode-text: hsl(200, 15%, 8%);
    --light-mode-input: hsl(0, 0%, 45%);
    --light-mode-bg: hsl(0, 0%, 98%);
    --light-mode-EL: hsl(0, 0%, 100%);
}

header {
    box-shadow: inset 0px -2px 15px -14px rgba(0, 0, 0, 1);
}

main {
    width: 100%;
    height: calc(100% - 6.2em);
    padding: 2em 1em;
}

.App__header__light {
    background-color: var(--light-mode-EL);
    color: var(--light-mode-text);
}

.App__header__dark {
    background-color: var(--dark-mode-EL);
    color: var(--dark-mode-text);
}

.App__main__light {
    background-color: var(--light-mode-bg);
    color: var(--light-mode-text);
}

.App__main__dark {
    background-color: var(--dark-mode-bg);
    color: var(--dark-mode-text);
}

.projects__modal__container {
    position: fixed;
    display: flex;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.projects__modal__wrapper {
    position: absolute;
    padding: 2.5em;
    max-width: 75em;
}

.projects__modal__close__icon {
    position: absolute;
    border: none;
    z-index: 100;
    right: 2em;
    top: -1em;
    cursor: pointer;
}

.projects__modal__close__icon__img {
    width: 3em;
}

.projects__modal__close__icon__img__dark {
    filter: invert(100%);
}