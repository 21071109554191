.navbar__container {
    width: 100%;
    height: 7.4em;
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 0.5em 1em;
    max-width: 1024px;
    margin: 0 auto;
}

.navbar__nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 10em;
    padding-block-start: 1.25em;
}

.navbar__pic__wrapper {
    min-width: 6.25em;
    max-width: 6.25em;
    height: 6.25em;
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
}

.navbar__pic {
    padding-block-start: 1.5em;
    width: 10em;
}

.nav__homepage {
    width: 100%;
}

.nav__homepage__link {
    text-decoration: none;
}

.nav__links__dark:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.15em;
    text-underline-offset: 5px;
    text-decoration-color: white;
}

.nav__links__light:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.15em;
    text-underline-offset: 5px;
    text-decoration-color: dark;
}

.nav__homepage__link__light {
    color: var(--light-mode-text);
}

.nav__homepage__link__dark {
    color: var(--dark-mode-text);
}

.nav__homepage__header__link {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.nav__homepage__divider {
    width: 2.5em;
    height: 4px;
    border-radius: 1em;
    margin: 0.25em 0 1.25em 0;   
}

.nav__homepage__divider__light {
    background-color: var(--light-mode-text);
}

.nav__homepage__divider__dark {
    background-color: var(--dark-mode-text);
}

.nav__links__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 13em;
    min-width: 13em;
    flex-wrap: nowrap;
}

.nav__links {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8em;  
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.nav__links__light {
    color: var(--light-mode-input);
}

.nav__links__dark {
    color: var(--dark-mode-input);
}

.theme__switch__button {
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.theme__switch__img {
    width: 2em;
}

.theme__switch__img__dark {
    filter: invert(100%);
}